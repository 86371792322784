'use strict';

// 舊IE提醒
var userAgent = window.navigator.userAgent;
if (
	userAgent.indexOf('MSIE 7.0') > 0 ||
	userAgent.indexOf('MSIE 8.0') > 0 ||
	userAgent.indexOf('MSIE 9.0') > 0 ||
	userAgent.indexOf('MSIE 10.0') > 0 ||
	!!userAgent.match(/Trident.*rv\:11\./) // IE11
) {
	location.href = 'browser.html';
}

(function (window, document) {
	/* ---------------------------------------- [START] Windows Setting */
	const html = document.documentElement;
	const body = document.body || document.querySelector('body');
	let ww = window.innerWidth;
	let wh = window.innerHeight;
	let ws = 0;
	function getScrollTop(target = window) {
		return (target.pageYOffset || html.scrollTop) - (html.clientTop || 0);
	}
	function getWinSet() {
		ww = window.innerWidth;
		wh = window.innerHeight;
		ws = getScrollTop();
	}
	on(window, 'load', getWinSet);
	on(window, 'resize', throttle(getWinSet, 50, 100));
	/* ---------------------------------------- [END] Windows Setting */

	/* ---------------------------------------- [START] 取得裝置判斷 */
	// 取得裝置判斷
	var isMobile = false;
	var isTablet = false;
	var isPhone = false;
	var deviceDetect = function () {
		// IsPhone
		isPhone = ww <= 640;

		// IsMobile
		// 防止測試時一直用開發者工具Resize出現Bug
		isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		if (isMobile) html.classList.add('is-mobile');
		else html.classList.remove('is-mobile');

		// IsTablet
		if (navigator.userAgent.match(/Android/i)) {
			if (!navigator.userAgent.match(/Mobile/i)) {
				isTablet = true;
			}
		} else if (navigator.userAgent.match(/BlackBerry|iPad|Opera Mini|IEMobile/i)) {
			isTablet = true;
		}
	};
	deviceDetect();
	on(window, 'resize', throttle(deviceDetect, 50, 100));
	/* ---------------------------------------- [END] 取得裝置判斷 */

	/* ---------------------------------------- [START] 判斷browser */
	var ua = navigator.userAgent;
	var browser = {
		isChrome: /chrome/i.test(ua),
		isFirefox: /firefox/i.test(ua),
		isSafari: /safari/i.test(ua),
		isIE: /msie/i.test(ua) || /trident/i.test(ua),
		isEdge: /edge/i.test(ua),
	};

	// 修正數值browser
	if (browser.isChrome) browser.isSafari = false;
	if (browser.isEdge) {
		browser.isChrome = false;
		browser.isSafari = false;
	}

	var browserIs;
	for (var key in browser) {
		if (browser[key]) {
			browserIs = key.split('is')[1];
			// 增加Class
			document.documentElement.classList.add(browserIs.toLowerCase());
			break;
		}
	}
	browser.is = browserIs;
	window.browser = browser; // 綁定於 windows

	// ----------------------------
	// 判斷 裝置
	// iOS
	var isiOS = ua.toLowerCase().match(/(iphone|ipod|ipad)/);
	isiOS && html.classList.add('ios');
	/* ---------------------------------------- [END] 判斷browser */

	/* ----------------------------------- [START] Loader 移除 */
	var loaderRemove = function () {
		var loader = document.querySelector('#loader');
		window.loader = loader; // 加到 window 上

		pageUnLoading();
	};
	window.addEventListener('load', loaderRemove);

	/* 頁面可呼叫的 function -------- */
	/* 開啟 Loading */
	window.pageLoading = function () {
		document.body.appendChild(loader);
		setTimeout(function () {
			loader.classList.remove('loaded');
		}, 100);
	};

	/* 關閉 Loading */
	window.pageUnLoading = function () {
		loader.classList.add('loaded');
		setTimeout(function () {
			loader.remove();
		}, 2000);
	};
	/* ----------------------------------- [END] Loader 移除 */

	/* ---------------------------------------- [START] Ease scroll */
	var buildEaseScroll = function () {
		if (window.EaseScroll === undefined) return false;
		const es = new EaseScroll({
			frameRate: 60,
			animationTime: 1000,
			stepSize: 100,
			pulseAlgorithm: 1,
			pulseScale: 6,
			pulseNormalize: 1,
			accelerationDelta: 20,
			accelerationMax: 1,
			keyboardSupport: true,
			arrowScroll: 30,
			touchpadSupport: true,
			fixedBackground: true,
			// disabledClass: 'modal-open',

			/* Browser Setting Control */
			browser: {
				Chrome: true,
				FireFox: false,
				Safari: true,
				IE: true,
				Edge: true,
			},
		});
	};
	on(window, 'load', buildEaseScroll);
	/* ---------------------------------------- [END] Ease scroll */

	/* ---------------------------------------- [START] Lazyload */
	/*
	 * 使用：https://github.com/tuupola/lazyload
	 * 尋找頁面上的 .lazyload 為執行 lazy load 物件
	 */
	var lazyloadTimer = 0;
	function buildLazyLoad() {
		if (lazyloadTimer < 5 && window.lazyload === undefined) {
			return setTimeout(() => {
				lazyloadTimer++;
				buildLazyLoad();
			}, 500);
		}
		lazyload();
	}
	on(window, 'load', buildLazyLoad);
	/* ---------------------------------------- [END] Lazyload */

	/* ---------------------------------------- [START] Foundation */
	/* Init Foundation  */
	on(window, 'load', function () {
		$(document).foundation();

		// data-smooth-scroll 項目，如果沒有設置 offset ，則自動轉換
		var smoothScrollEl = $('[data-smooth-scroll]').not('[data-offset]');
		if (smoothScrollEl.length) {
			var smoothScrollElFd = null; // 裝 Foundation 項目

			var smoothScrollbreakpoint = window.matchMedia('(min-width: 1024px)');
			var smoothScrollbreakpointChecker = function () {
				if (smoothScrollbreakpoint.matches) {
					// Large
					smoothScrollElFd.options.offset = 80 + 20;
				} else {
					// Small + Medium
					smoothScrollElFd.options.offset = 44;
				}
			}

			// Start
			smoothScrollElFd = new Foundation.SmoothScroll(smoothScrollEl);
			smoothScrollbreakpointChecker();
			smoothScrollbreakpoint.addListener(smoothScrollbreakpointChecker);
		}
	});

	$(document).on('open.zf.reveal', '[data-reveal]', function () {
		// 調整內容為垂直置中
		var $self = $(this);
		var top = (wh - $self.innerHeight()) / 2;
		if (top < 0) top = 0;
		$self.css('top', top);
	});
	/* ---------------------------------------- [END] Foundation */

	/* ---------------------------------------- [START] 選單增加Active */
	function headerSetActive(option) {
		let active = -1;
		switch (option) {
			case 'solutions':
				active = 0;
				break;
			case 'story':
				active = 1;
				break;
			case 'investors':
				active = 2;
				break;
			case 'news':
				active = 3;
				break;
			case 'careers':
				active = 4;
				break;
			case 'esg':
				active = 5;
				break;
			case 'about':
				active = 6;
				break;
		}

		if (active !== -1) {
			document.querySelector('.hd-menu__list').children[active].classList.add('is-active');
		}
	}
	window.headerSetActive = headerSetActive;
	/* ---------------------------------------- [END] 選單增加Active */

	/* ---------------------------------------- [START] 選單下滑更改樣式 */
	// const doc = document.documentElement;

	const header = document.querySelector('#header');
	const headerClassScroll = 'is-collapse';
	const headerClassScrollDown = 'is-scroll-down';
	const headerClassScrollUp = 'is-scroll-up';

	let windowScrollTopCache = getScrollTop();
	let windowScrollStatus = null;

	/**
	 * 更改向上滑動與向下滑動狀態
	 * @param {string} dir 滑動方向，輸入['down'|'up']
	 */
	function scrollStatusChange(dir) {
		if (windowScrollStatus === dir) {
			return false;
		} else {
			if (dir === 'down') {
				scrollStatusDown(header);
				scrollStatusDown(body);
			} else {
				scrollStatusUp(header);
				scrollStatusUp(body);
			}
			windowScrollStatus = dir;
		}
	}

	function scrollStatusDown(el) {
		el.classList.add(headerClassScrollDown);
		el.classList.remove(headerClassScrollUp);
	}

	function scrollStatusUp(el) {
		el.classList.remove(headerClassScrollDown);
		el.classList.add(headerClassScrollUp);
	}

	/* 滑動主要Function */
	function headerScrollHandler() {
		ws = getScrollTop();

		// 確認上滑與下滑狀態
		if (ws > windowScrollTopCache) {
			scrollStatusChange('down');
		} else if (ws !== windowScrollTopCache) {
			scrollStatusChange('up');
		}
		windowScrollTopCache = ws;

		// 下滑超過一定高度出現樣式：更改選單樣式、GoTop隱藏出現
		if (ws >= 200) {
			header.classList.add(headerClassScroll);
		} else {
			header.classList.remove(headerClassScroll);
		}

		ftFixedShow();
	}
	on(window, 'load', headerScrollHandler);
	on(window, 'scroll', headerScrollHandler);
	/* ---------------------------------------- [END] 選單下滑更改樣式 */

	/* ----------------------------------- [START] Hamburger Click */
	function hamburgerMenuClickBuild() {
		var hamburgerMenu = document.querySelector('#hd-ham');
		var hamburgerMenuClassIsActive = 'is-open';
		var mainMenu = document.querySelector('#hd-menu');
		var mainMenuBg = document.querySelector('.hd-menu__bg');

		on(hamburgerMenu, 'click', function () {
			const _this = this;
			if (!hamburgerMenu.classList.contains(hamburgerMenuClassIsActive)) {
				// 開啟
				_this.setAttribute('aria-expanded', true);

				// Prevent Page Scrolling When a Modal is Open
				// https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
				// When the modal is shown, we want a fixed body
				// document.body.style.top = `-${window.scrollY}px`;
				// document.body.style.position = 'fixed';
				document.body.style.overflow = 'hidden';
			} else {
				// 關閉
				_this.setAttribute('aria-expanded', false);

				// When the modal is hidden, we want to remain at the top of the scroll position
				// const scrollY = document.body.style.top;
				// document.body.style.position = '';
				// document.body.style.top = '';
				// window.scrollTo(0, parseInt(scrollY || '0') * -1);
				document.body.style.overflow = '';
			}
			hamburgerMenu.classList.toggle(hamburgerMenuClassIsActive);
			mainMenu.classList.toggle(hamburgerMenuClassIsActive);
		});

		on(mainMenuBg, 'click', function () {
			const _this = this;
			// 關閉
			document.body.style.overflow = '';
			hamburgerMenu.setAttribute('aria-expanded', false);
			hamburgerMenu.classList.remove(hamburgerMenuClassIsActive);
			mainMenu.classList.remove(hamburgerMenuClassIsActive);
		});
	}
	on(window, 'load', hamburgerMenuClickBuild);
	/* ----------------------------------- [END] Hamburger Click */

	/* ----------------------------------- [START] Global Search + Lang */
	var hdPanelClassOpen = 'is-open';
	var hdPanelClassActive = 'is-active';
	var hdPanelOpenArr = []; // 紀錄物件關閉 Array
	var hdPanelCache = []; // 紀錄有多少物件設定
	var key = {
		esc: 27,
	};

	function hdPanelOpenArrGetAllClose() {
		return hdPanelOpenArr.every((val) => !val);
	}

	/* 關閉所有物件 */
	/**
	 * @param {boolean} filterSelf - 是否要篩選掉自己
	 * @param {boolean} $el - 傳入 Self 物件(jQ)
	 */
	function hdPanelCloseAll(filterSelf, $el) {
		hdPanelCache.forEach(function (opt) {
			if (filterSelf ? opt.$el !== $el : true) {
				opt.$panel.close();
			}
		});

		if (!filterSelf) {
			hdPaneloffEvent();
		}
	}

	/* 滾動 => 關閉各種Panel */
	function hdPanelScrollHandler() {
		// hdPanelCloseAll();
		off(window, 'scroll', hdPanelScrollHandler);
	}

	/* 開啟後監測按鈕事件 */
	function hdPanelKeyboardHandler(event) {
		if (event.keyCode === key.esc) {
			hdPanelCloseAll();
			off(document, 'keyup', hdPanelKeyboardHandler);
		}
	}

	function hdPaneloffEvent() {
		off(window, 'scroll', hdPanelScrollHandler);
		off(document, 'keyup', hdPanelKeyboardHandler);
	}

	/* Header Top 按鈕建立 */
	/**
	 * @param {object} $el - 觸發的按鈕物件(jQ)
	 * @param {object} option - 設定
	 * 
	 * {
			$panel: 開啟的面板物件（增加 is-open Class 的物件）(jQ),
			$bg: 背景物件(jQ),
			openHandler: 開啟後的事件,
			closeHandler: 關閉後的事件
		}
	 */
	function hdPanelBuild($el, option) {
		var classOpen = hdPanelClassOpen;
		var classActive = hdPanelClassActive;
		var currentIndex = hdPanelCache.length;

		hdPanelOpenArr.push(false); // 紀錄物件關閉 Array

		var opt = {
			index: currentIndex,
			$el: $el,
			$panel: option.$panel || null, // 開啟的面板物件（增加 is-open Class 的物件）(jQ)
			$bg: option.$bg || null, // 背景物件(jQ)
			openHandler: option.openHandler, // 開啟後的事件
			closeHandler: option.closeHandler, // 關閉後的事件
		};

		opt.$panel.open = function () {
			if (hdPanelOpenArrGetAllClose()) {
				on(window, 'scroll', hdPanelScrollHandler);
				on(document, 'keyup', hdPanelKeyboardHandler);
			}

			/* 更改開啟關閉選項 */
			hdPanelOpenArr[currentIndex] = !hdPanelOpenArr[currentIndex];

			if (hdPanelOpenArr[currentIndex]) {
				/* 開啟 */
				/* Close All (要篩選掉自己) */
				hdPanelCloseAll(true, $el);

				/* Open Self */
				$el.toggleClass(classActive);
				opt.$panel.toggleClass(classOpen);

				/* Open Callback */
				opt.openHandler && typeof opt.openHandler === 'function' && opt.openHandler();
			} else {
				/* 關閉 */
				opt.$panel.close();
				hdPaneloffEvent();
			}
		};

		opt.$panel.close = function () {
			hdPanelOpenArr[currentIndex] = false;

			opt.$panel.removeClass(classOpen);
			$el.removeClass(classActive);
			$el.blur();

			/* Close Callback */
			opt.closeHandler && typeof opt.closeHandler === 'function' && opt.closeHandler();
		};

		/* Button Click */
		$el.on('click', function () {
			opt.$panel.open();
		});

		/* Bg Click */
		opt.$bg.on('click', function () {
			opt.$panel.close();
		});

		/* 推上設定 */
		hdPanelCache.push(opt);
	}

	on(window, 'load', function () {
		/* Search */
		var hdSearchPanel = $('#hd-search-panel');
		var hdSearchOpenBtn = $('.hd-search__button-open');
		var hdSearchBg = $('.hd-search__bg');
		var hdSearchInput = hdSearchPanel.find('input');
		hdPanelBuild(hdSearchOpenBtn, {
			$panel: hdSearchPanel,
			$bg: hdSearchBg,
			openHandler: function () {
				hdSearchInput.focus();
			},
			closeHandler: function () {
				hdSearchInput.blur();
			},
		});

		/* Lang */
		var hdLangPanel = $('#hd-lang-panel');
		var hdLangOpenBtn = $('.hd-lang__button-open');
		var hdLangBg = $('.hd-lang__bg');
		hdPanelBuild(hdLangOpenBtn, {
			$panel: hdLangPanel,
			$bg: hdLangBg,
			// openHandler: function() {}
			// closeHandler: function() {}
		});
	});

	/* ----------------------------------- [END] Global Search + Lang */

	/* ---------------------------------------- [START] 選單項目開啟關閉 */
	/* 選單項目開啟關閉(Header Menu, Footer Sitemap) */
	function ToggleMenu(el, enableSize, callback) {
		var toggleMenuEl = document.querySelectorAll(el);

		if (toggleMenuEl === undefined || toggleMenuEl === null || toggleMenuEl.length <= 0) return false;

		[].forEach.call(toggleMenuEl, function (el) {
			el.menu = {
				el: el,
				parent: el.parentElement,
				subLink: el.nextElementSibling ? el.nextElementSibling.querySelectorAll('a') : null,
				classOpen: 'is-open',
				classTl3d: 'translate3d',
				timer: null,
			};

			if (!el.menu.subLink) return false;

			on(el, 'click', function (event) {
				if (ww < enableSize && !el.classList.contains('is-native')) {
					// 停止原生事件(避免有連結的會跳轉)
					event.preventDefault();

					var selfMenu = this.menu;

					// Blur
					this.blur();

					// 開啟瀏覽器加速效能(transform3d(0,0,0))
					selfMenu.subLink.forEach((item) => {
						item.classList.add(selfMenu.classTl3d);
					});

					// Toggle open Class
					selfMenu.parent.classList.toggle(selfMenu.classOpen);

					// 清除transform3d
					if (selfMenu.timer !== null) clearTimeout(selfMenu.timer);
					selfMenu.timer = setTimeout(function () {
						selfMenu.subLink.forEach((item) => {
							item.classList.remove(selfMenu.classTl3d);
						});
					}, 1000);

					if (typeof callback === 'function') {
						callback.call(this);
					}
				}
			});
		});
	}

	on(window, 'load', function () {
		const menuBreakpoint = 1440;
		/* Header Menu Open Second */
		var hdMenuLink = new ToggleMenu('.hd-menu__link', menuBreakpoint, function (el) {
			var _this = this;
			var classOpen = _this.menu.classOpen;
			if (!_this.parentElement.classList.contains(classOpen)) {
				/* 關閉 => 移除內部已開啟的物件 */
				_this.parentElement.querySelectorAll('.' + classOpen).forEach((el) => {
					el.classList.remove(classOpen);
				});
			}
		});

		/* PC 點選第一層按鈕 => Blur() */
		document.querySelectorAll('.hd-menu__link').forEach(function (el) {
			if (!isMobile && window.innerWidth >= menuBreakpoint) {
				el.blur();
			}
		});

		/* Header Menu Third */
		/* 2021/05/11 確認手機板無子選單 */
		// var hdMenuSecLink = new ToggleMenu('.hd-menu__sec__title', 1024);

		/* Footer Sitemap */
		var ftSitemapMenu = new ToggleMenu('.ft-sitemap__title', 640);
	});
	/* ---------------------------------------- [END] 選單項目開啟關閉 */

	// ------------------------------- [START] GoTop
	// 1. offset()
	var ft = $('#footer');
	var ftOffsetTop = ft.offset().top;
	var ftFixedEl = $('#ft-fixed-box');
	var ftFixedElHeight = null;
	var ftFixedClassSticky = 'is-fixed';
	var ftFixedAlign = 'top'; //
	var ftFixedOffset = {
		0: -20,
		640: 50,
	};

	/* ----- */
	/* Go Top Click */
	var goTop = $('#go-top');
	goTop.on('click touchstart', function (e) {
		e.preventDefault();
		$('html, body')
			.stop()
			.animate(
				{
					scrollTop: 0,
				},
				400,
				'swing',
				function () {
					document.querySelector('a').focus();
					document.querySelector('a').blur();
				}
			);
	});

	/* ----- */
	// 取得修正數值
	const breakpointGoTop = window.matchMedia('(min-width: 640px)');
	var breakpointChecker__goTop = function () {
		goTopScrollOffset = (function(){
			if (breakpointGoTop.matches) {
				const result = ftFixedEl.height() + 136  // 410 = 360(el height) + 136(padding-top)
				return result
			} else {
				return -20
			}
		})()
	};
	breakpointChecker__goTop();
	breakpointGoTop.addListener(breakpointChecker__goTop);

	// 取得FooterOffsetTop
	var getFooterOffsetTop = function () {
		ftOffsetTop = ft.offset().top;
	};
	on(window, 'resize', throttle(getFooterOffsetTop, 50, 100));

	/* Go Top 出現 */
	function ftFixedShow() {
		ftFixedEl.css({ opacity: '1', 'pointer-events': '' });
		// 因為Footer位置可能會一直變動 => 寫在Scroll Event內
		if (ws + wh > ft.offset().top + goTopScrollOffset) {
			ftFixedEl.removeClass(ftFixedClassSticky);
		} else {
			ftFixedEl.addClass(ftFixedClassSticky);
		}
	}
	on(window, 'load', ftFixedShow);
	// ------------------------------- [END] GoTop

	// ------------------------------- [START] Search Clean
	function buildSearchClean() {
		const searchCleanEl = document.querySelectorAll('.hd-search__clean');
		const searchCleanHideClass = 'hide';

		const detectLen = function (input, item) {
			if (input.value.trim().length === 0) {
				item.classList.add(searchCleanHideClass);
			} else {
				item.classList.remove(searchCleanHideClass);
			}
		};

		[].forEach.call(searchCleanEl, function (clean) {
			const parent = clean.parentElement;
			const currentInput = parent.querySelector('.hd-search__input');

			// Start
			detectLen(currentInput, clean);

			// Event Detect
			on(currentInput, 'input', function () {
				detectLen(this, clean);
			});

			on(clean, 'click', function () {
				setTimeout(() => {
					currentInput.value = '';
					currentInput.focus();
				}, 10);
				this.classList.add(searchCleanHideClass);
			});
		});
	}
	on(window, 'load', buildSearchClean);
	// ------------------------------- [END] Search Clean

	/* ---------------------------------------- [START] Page Visual (Inside) */
	var visual = $('.page-banner');
	var visualH;
	var visualImg;
	var visualMoveItem;
	var scrollChecktimer;

	function visualOnScroll() {
		if (isMobile || visual === undefined) return false;
		if (ws > visualH) {
			visualImg.css('will-change', '');
			visualMoveItem.css('will-change', '');
			return false;
		} else if (ws <= 0) {
			visualImg.css({
				opacity: 1,
				transform: 'translateY(0)',
				'will-change': '',
			});
			visualMoveItem.css({
				transform: 'translateY(0)',
				'will-change': '',
			});
		} else {
			var y = ws > 0 ? ws / 3 : 0;
			// var s = ws > 0 ? 1 + 0.001 * ws : 1;
			var a = ws > 0 ? 1 - 0.002 * ws : 1;
			visualImg.css({
				opacity: a,
				transform: 'translateY(' + y + 'px)',
				'will-change': 'transform opacity',
			});
			visualMoveItem.css({
				transform: 'translateY(' + y + 'px)',
				'will-change': 'transform opacity',
			});
		}

		// jQuery scroll() detect when user stops scrolling
		// https://stackoverflow.com/a/14092859
		scrollChecktimer && clearTimeout(scrollChecktimer);
		scrollChecktimer = setTimeout(function () {
			// do something
			visualImg.css('will-change', '');
			visualMoveItem.css('will-change', '');
		}, 250);
	}
	if (visual.length > 0) {
		on(window, 'load', function () {
			visualH = visual.innerHeight();
			visualImg = visual.children('.page-banner__bg');
			visualMoveItem = $('.visual-move'); // 與Banner一同增加translateY移動的物件

			visualOnScroll();
			on(window, 'scroll', visualOnScroll);
		});
	}
	/* ---------------------------------------- [END] Page Visual (Inside) */

	/* ---------------------------------------- [START] Fixed Menu */
	function fixedMenuBuild() {
		var fixedMenuEl = document.querySelector('.js-fixed-menu');
		if (fixedMenuEl === null) {
			return false;
		}

		var fixedMenuOffsetTop = fixedMenuEl.offsetTop;
		var headerHeight = header.clientHeight;
		var fixedMenuClass = 'is-sticky';

		function fixedMenuScroll() {
			if (ws + headerHeight > fixedMenuOffsetTop) {
				fixedMenuEl.classList.add(fixedMenuClass);
			} else {
				fixedMenuEl.classList.remove(fixedMenuClass);
			}
		}

		on(window, 'scroll', fixedMenuScroll);
		fixedMenuScroll();
	}

	on(window, 'load', fixedMenuBuild);
	/* ---------------------------------------- [END] Fixed Menu */

	/* ---------------------------------------- [START] 橫向選單：有ScrollBar時，設定操作按鈕 */
	/* 監測是否有 ScrollBar，增加控制 */
	var setScrollCtrl = function (selector) {
		// 抓取物件
		var navs = document.querySelectorAll(selector);

		[].forEach.call(navs, function (nav) {
			var list = nav.querySelector('.js-scroll-ctrl-list');
			var $list = $(list);
			var arrowAppendTarget = list.parentElement;

			var hasScroll; // 確認選單是否有 Scroll Bar

			// 向左箭頭
			var arrowLeft = document.createElement('div');
			arrowLeft.className = 'btn-arrow left disabled';
			arrowLeft.setAttribute('role', 'button');
			arrowLeft.setAttribute('tabindex', '0');
			var arrowLeftIcon = document.createElement('i');
			arrowLeftIcon.className = 'be-icon be-icon-arrow-left';
			arrowLeft.appendChild(arrowLeftIcon);

			// 向右箭頭
			var arrowRight = document.createElement('div');
			arrowRight.className = 'btn-arrow right';
			arrowRight.setAttribute('role', 'button');
			arrowRight.setAttribute('tabindex', '0');
			var arrowRightIcon = document.createElement('i');
			arrowRightIcon.className = 'be-icon be-icon-arrow-right';
			arrowRight.appendChild(arrowRightIcon);

			// 綁定事件：點擊
			arrowLeft.addEventListener('click', function () {
				scrollMethod($list, 'left');
			});
			arrowRight.addEventListener('click', function () {
				scrollMethod($list, 'right');
			});

			// 生成物件
			arrowAppendTarget.appendChild(arrowLeft);
			arrowAppendTarget.appendChild(arrowRight);

			// [START] 滑鼠拖曳
			// Ref: https://codepen.io/thenutz/pen/VwYeYEE
			var mouseEnable = false;
			var isDragClass = 'is-drag';
			var isDown = false;
			var startX = 0;
			var scrollLeft = null;

			function mouseDown(e) {
				// console.log('mouseDown');
				if (isMobile || !hasScroll) return;
				isDown = true;
				list.classList.add(isDragClass);
				startX = e.pageX - list.offsetLeft;
				scrollLeft = list.scrollLeft;
			}

			function mouseLeave() {
				// console.log('mouseLeave');
				if (isMobile || !hasScroll) return;
				isDown = false;
				list.classList.remove(isDragClass);
				document.activeElement.blur();
			}

			function mouseMove(e) {
				// console.log('mouseMove');
				if (isMobile || !hasScroll || !isDown) return;
				e.preventDefault();
				const x = e.pageX - list.offsetLeft;
				const walk = (x - startX) * 3; //scroll-fast
				list.scrollLeft = scrollLeft - walk;
			}

			// 滑鼠事件建立(限電腦版、有滾軸的狀態)
			function mouseEventInit() {
				if (isMobile || !hasScroll || mouseEnable) return;
				mouseEnable = true;
				list.addEventListener('mousedown', mouseDown);
				list.addEventListener('mouseleave', mouseLeave);
				list.addEventListener('mouseup', mouseLeave);
				list.addEventListener('mousemove', mouseMove);
			}

			// 滑鼠事件破壞(限電腦版)
			function mouseEventDestroy() {
				if (isMobile || !mouseEnable) return;
				list.removeEventListener('mousedown', mouseDown);
				list.removeEventListener('mouseleave', mouseLeave);
				list.removeEventListener('mouseup', mouseLeave);
				list.removeEventListener('mousemove', mouseMove);
				mouseEnable = false;
			}
			// [END] 滑鼠拖曳

			// Resize
			function scrollCtrlResize() {
				hasScroll = checkHasScroll(list);

				if (hasScroll) {
					nav.classList.add('has-scroll');
					mouseEventInit(); // 滑鼠事件建立
				} else {
					nav.classList.remove('has-scroll');
					mouseEventDestroy(); // 滑鼠事件破壞
				}

				scrollDetected();
			}

			// 監測物件顯示/消失
			function scrollDetected() {
				var self = this.tagName ? this : list;

				// 沒有滾軸直接讓左右物件消失
				if (!hasScroll) {
					arrowLeft.classList.add('disabled');
					arrowRight.classList.add('disabled');
					return false;
				}

				// Disabled - 向左箭頭
				if (self.scrollLeft === 0) {
					nav.classList.add('is-scroll-start');
					arrowLeft.classList.add('disabled');
				} else {
					nav.classList.remove('is-scroll-start');
					arrowLeft.classList.remove('disabled');
				}

				// Disabled - 向右箭頭
				if (self.scrollLeft + self.clientWidth === self.scrollWidth) {
					nav.classList.add('is-scroll-end');
					arrowRight.classList.add('disabled');
				} else {
					nav.classList.remove('is-scroll-end');
					arrowRight.classList.remove('disabled');
				}
			}

			scrollCtrlResize();
			window.addEventListener('resize', throttle(scrollCtrlResize, 50, 100));
			list.addEventListener('scroll', throttle(scrollDetected, 50, 100));
		});

		function checkHasScroll(el) {
			return el.scrollWidth > el.clientWidth;
		}

		function scrollMethod($el, dir) {
			var move = dir === 'left' ? '-=' + $el.get(0).clientWidth : '+=' + $el.get(0).clientWidth;
			$el.animate({ scrollLeft: move });
		}
	};

	// Nav Scroll To Active(jQ)
	function navScrollCtrlToActive() {
		if ($('.js-scroll-ctrl').length > 0) {
			var targetActive = $('.js-scroll-ctrl').find('.is-active');
			if (targetActive.length) {
				var list = targetActive.parents('.js-scroll-ctrl-list');
				var adjust = ww < 640 ? 40 - 15 : ww < 1024 ? 70 - 15 : null; // -15 === item padding-left

				if (adjust === null) {
					if ($('.js-scroll-ctrl').hasClass('has-overview')) {
						adjust =
							parseInt($('.unit-menu__fake-overview').innerWidth()) +
							parseInt($('.unit-menu__fake-overview').css('margin-right')) * 2;
					} else {
						adjust = 0;
					}
				}

				var offsetLeft = targetActive.get(0).offsetLeft - adjust;

				list.animate({ scrollLeft: offsetLeft }, 300);
			}
		}
	}

	// Init
	on(window, 'load', function () {
		setScrollCtrl('.js-scroll-ctrl');

		navScrollCtrlToActive();
	});
	/* ---------------------------------------- [END] 橫向選單：有ScrollBar時，設定操作按鈕

	/* ---------------------------------------- [START] Tel Change el */
	/* 如果不是 Mobile 時，用<span>取代<a>取消連結
	 * 沒有 RWD breakpoint 變換
	 */
	var telElArray = [];
	function telChangeBuild() {
		var telChangeEl = document.querySelectorAll('.js-tel-change');
		if (telChangeEl.length) {
			[].forEach.call(telChangeEl, function (el) {
				var normalEl = createElementFromHTML(el.outerHTML.replace(/^\<a/, '<span').replace(/<\/a>/, '</span>')); // 取代為 span
				normalEl.removeAttribute('href');

				var obj = {
					link: el,
					normal: normalEl,
				};

				telElArray.push(obj);
			});

			if (!isMobile) {
				telElArray.forEach(function (item) {
					var oldEl = item.link;
					var newEl = item.normal;
					oldEl.parentNode.replaceChild(newEl, oldEl);
				});
			}
		}
	}
	on(window, 'load', telChangeBuild);
	/* ---------------------------------------- [END] Tel Change el */

	/* ---------------------------------------- [Start] Scroll to hash */
	// Scroll to hash
	// Ref: https://gist.github.com/srikat/10812884#file-gistfile4-js
	var hash;

	// Check Faq hash and Stop Scroll
	function checkHash() {
		// location
		hash = location.hash;
		if (hash !== '' && document.querySelector(hash) !== null) {
			var $el = $(hash);

			// 避免跟 Foundation Component Magellen 衝突到
			if ($el.attr('data-magellan-target') !== undefined) {
				return false;
			}

			var $target = $el.attr('data-scroll-target') ? $($el.attr('data-scroll-target')) : $el;
			var scrollTop = $target.offset().top - $('#header').innerHeight() - ($('.unit-menu').innerHeight() || 0);

			// Stop wheel Scroll
			on(window, 'mousewheel', stopMouseWheel);

			// 滑動到指定項目
			$('html, body')
				.stop()
				.animate({ scrollTop: scrollTop }, 400, function () {
					// Open wheel Scroll
					off(window, 'mousewheel', stopMouseWheel);
				});
		}
	}
	on(window, 'load', function () {
		setTimeout(function () {
			checkHash();
		}, 100);
	});

	// Reference: http://solidlystated.com/scripting/javascript-disable-mouse-wheel/
	function stopMouseWheel(e) {
		if (!e) {
			e = window.event;
		} /* IE7, IE8, Chrome, Safari */
		if (e.preventDefault) {
			e.preventDefault();
		} /* Chrome, Safari, Firefox */
		e.returnValue = false; /* IE7, IE8 */
	}
	/* ---------------------------------------- [END] Scroll to hash */

	/* ---------------------------------------- [START] ScrollTriggerAni */
	// 有滑動到該區增加動態者增加class "js-ani" ， 滑動到該區增加class "is-animated"
	// 用套件也好，換其它習慣方式也罷，請達成↑目的
	let aniSec = document.querySelectorAll('.js-ani');
	const scrollTriggerAniThrottle = throttle(scrollTriggerAni, 200, 500); // 節流作用

	function scrollTriggerAni() {
		for (var i = 0; i < aniSec.length; i++) {
			if (isInViewport(aniSec[i])) {
				aniSec[i].classList.add('is-animated');
			}
		}

		cleanTrigger();

		/* If load all of the item, stop Listener */
		if (aniSec.length === 0) {
			off(window, 'scroll', scrollTriggerAniThrottle);
		}
	}

	function cleanTrigger() {
		aniSec = Array.prototype.filter.call(aniSec, function (ani) {
			return !ani.classList.contains('is-animated');
		});
	}

	on(window, 'load', scrollTriggerAni);
	on(window, 'scroll', scrollTriggerAniThrottle);
	/* ---------------------------------------- [END] ScrollTriggerAni */
})(window, document);

/* ---------------------------------------- [START] Banner Hover Effect */
(function (window, document) {
	function buildBannerHoverEffect() {
		var bannerEl = document.querySelector('.page-banner');
		var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

		if (isMobile || bannerEl === null) {
			return false;
		}

		var svgWrapper = document.createElement('div');
		svgWrapper.className = 'page-banner__hover-effect';

		var bannerCalcRatio = function () {
			var bannerWidth = bannerEl.clientWidth;
			var bannerHeight = bannerEl.clientHeight;
			var bannerRatio = bannerWidth / bannerHeight;

			var svgWidth = 1920;
			var svgHeight = 330;
			var svgRatio = svgWidth / svgHeight;
			var svgWidthNew = bannerWidth;
			var svgHeightNew = bannerHeight;

			if (svgRatio > bannerRatio) {
				// Height => 100% & 計算等比寬
				// svgHeightNew = bannerHeight;
				svgWidthNew = (svgWidth / svgHeight) * bannerHeight;
			} else if (svgRatio > bannerRatio) {
				// svgWidthNew = bannerWidth;
				svgHeightNew = (svgHeight / svgWidth) * bannerWidth;
			}

			svgWrapper.style.width = svgWidthNew + 'px';
			svgWrapper.style.height = svgHeightNew + 'px';
		};

		var scriptOrigin = currentScriptPath();
		fetch(scriptOrigin + 'images/banner-hover.svg')
			.then((r) => r.text())
			.then((text) => {
				svgWrapper.innerHTML = text;
				bannerEl.appendChild(svgWrapper);

				bannerCalcRatio();
			})
			.catch(console.error.bind(console));

		window.addEventListener('resize', bannerCalcRatio);
	}

	// https://stackoverflow.com/a/26023176/11240898
	/**
	 * Current Script Path | 取得正確的資源位置
	 *
	 * Get the dir path to the currently executing script file
	 * which is always the last one in the scripts array with
	 * an [src] attr
	 */
	function currentScriptPath() {
		var scripts = document.querySelectorAll('script[src]');
		var currentScript = scripts[scripts.length - 1].src;
		var currentScriptChunks = currentScript.split('/');
		var currentScriptFile = currentScriptChunks[currentScriptChunks.length - 1];

		return currentScript.replace(currentScriptFile, '').split('js/')[0];
	}

	window.addEventListener('load', buildBannerHoverEffect);
})(window, document);
/* ---------------------------------------- [END] Banner Hover Effect */

/* ---------------------------------------- [START] Cookie 通知 */
(function (window, document) {
	var cookieNotice = document.querySelector('#cookie-notice');
	var cookieNoticeBtn = cookieNotice ? cookieNotice.querySelector('button') : null;
	var cookieMaxAge; // 紀錄時效
	var setName = 'cookieNoticeHide';

	function checkCookie() {
		/* 頁面上沒有 Cookie 物件 => 不執行 */
		if (!cookieNotice) {
			return false;
		}

		/* 確認是否已經有確認Cookie */
		if (getCookie(setName) === 'true') {
			/* 已有確認 */
			cookieNotice.remove();
		} else {
			/* 未確認 */
			cookieNotice.classList.remove('is-hide');

			/* 取得過期時間設定 */
			cookieMaxAge = cookieNotice.getAttribute('data-max-age') || 180;
			if (typeof cookieMaxAge === 'string') {
				cookieMaxAge = parseInt(cookieMaxAge);
			}
		}

		/* 按下「確認」功能 */
		cookieNoticeBtn.addEventListener('click', function () {
			setCookie(setName, true, cookieMaxAge); // 時效設定單位：天
			cookieNotice.classList.add('to-hide');
			setTimeout(function () {
				cookieNotice.remove();
			}, 1000);
		});
	}

	window.addEventListener('load', checkCookie);

	/* ------------------------------------------------------- Tool */
	/* https://stackoverflow.com/a/24103596/11240898 */
	function setCookie(name, value, days) {
		var expires = '';
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
			expires = '; expires=' + date.toUTCString();
		}
		document.cookie = name + '=' + (value || '') + expires + '; path=/';
	}

	function getCookie(name) {
		var nameEQ = name + '=';
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i].trim();
			if (c.indexOf(nameEQ) === 0) {
				return c.replace(nameEQ, '');
			}
		}
		return null;
	}
})(window, document);
/* ---------------------------------------- [END] Cookie 通知 */

/* ---------------------------------------- [START] 停機公告 */
(function (window, document) {
	var stopNotice = document.querySelector('#stop');
	var stopNoticeBtn = stopNotice ? stopNotice.querySelector('.close-btn') : null;
	var stopMaxAge; // 紀錄時效
	var setName = 'stopNoticeHide';

	function checkStop() {
		/* 頁面上沒有 Cookie 物件 => 不執行 */
		if (!stopNotice) {
			return false;
		}

		/* 確認是否已經有確認Cookie */
		if (getCookie(setName) === 'true') {
			/* 已有確認 */
			stopNotice.remove();
		} else {
			/* 未確認 */
			stopNotice.classList.remove('is-hide');
			// setTimeout(function () {
			// 	$('.announcement').addClass('is-show');
			// }, 1000);

			/* 取得過期時間設定 */
			stopMaxAge = stopNotice.getAttribute('data-max-age') || 1;
			if (typeof stopMaxAge === 'string') {
				stopMaxAge = parseInt(stopMaxAge);
			}
		}

		/* 按下「確認」功能 */
		stopNoticeBtn.addEventListener('click', function () {
			setCookie(setName, true, stopMaxAge); // 時效設定單位：天
			stopNotice.classList.add('is-hide');
			setTimeout(function () {
				stopNotice.remove();
			}, 1000);
		});
	}

	window.addEventListener('load', checkStop);

	/* ------------------------------------------------------- Tool */
	/* https://stackoverflow.com/a/24103596/11240898 */
	function setCookie(name, value, days) {
		var expires = '';
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
			expires = '; expires=' + date.toUTCString();
		}
		document.cookie = name + '=' + (value || '') + expires + '; path=/';
	}

	function getCookie(name) {
		var nameEQ = name + '=';
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i].trim();
			if (c.indexOf(nameEQ) === 0) {
				return c.replace(nameEQ, '');
			}
		}
		return null;
	}

	$(document).on('click', '.close-btn', function () {
		$('.announcement').addClass('is-hide');
	});

})(window, document);
/* ---------------------------------------- [END] 停機公告 */
